<template>
  <v-app>
    <v-app-bar app color="white">
      <v-toolbar-title class="pl-0">{{ $route.meta.pageName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            v-bind="attrs"
            v-on="on"
            small
            color="white"
            class="px-1"
          >
            <img
              v-if="$i18n.locale === 'vi'"
              src="@/assets/sidebars/vietnam.png"
              alt=""
              style="height: 32px"
            />
            <img
              v-else
              src="@/assets/sidebars/usa.png"
              alt=""
              style="height: 32px"
            />
            <v-icon small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            class="cursor-pointer"
            @click="changeLanguage('vi')"
            :class="{ 'primary white--text': $i18n.locale === 'vi' }"
          >
            <v-list-item-title>
              <div class="d-flex align-center">
                <img
                  src="@/assets/sidebars/vietnam.png"
                  alt=""
                  style="height: 22px"
                  class="mr-1"
                />
                <span>Tiếng Việt</span>
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            class="cursor-pointer"
            @click="changeLanguage('en')"
            :class="{ 'primary white--text': $i18n.locale === 'en' }"
          >
            <v-list-item-title>
              <div class="d-flex align-center">
                <img
                  src="@/assets/sidebars/usa.png"
                  alt=""
                  style="height: 22px"
                  class="mr-1"
                />
                <span>English</span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <div class="bg-img"></div>

    <v-container class="w-100 h-100 d-flex align-center justify-center">
      <div>
        <div class="text-h5 mb-3 text-center">
          {{ $t("labels.account_register") }}
        </div>
        <v-card outlined max-width="400px" class="mx-auto" v-if="!isSuccess">
          <v-card-text v-if="!isOtpConfirmed">
            <v-row dense>
              <v-col cols="12">
                <SelectCountry
                  name="id_country"
                  :id-country="employee.id_country"
                  @onChange="onChange"
                  ref="selectCountryRef"
                  class="c-input-small"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="employee.phone"
                  autocomplete="off"
                  :label="`${$t('labels.phone_number')} *`"
                  outlined
                  dense
                  hide-details
                  class="c-input-small"
                  @keyup.enter="checkOtp"
                ></v-text-field>
              </v-col>

              <v-spacer></v-spacer>
              <v-col cols="12">
                <v-btn
                  block
                  color="success"
                  @click="checkOtp"
                  :disabled="isDisabledBtnNext"
                >
                  {{ $t("labels.next") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="employee.name"
                  autocomplete="off"
                  :label="`${$t('labels.full_name')} *`"
                  outlined
                  dense
                  hide-details
                  class="c-input-small"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="employee.password"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :label="`${$t('labels.password')} *`"
                  counter
                  @click:append="showPassword = !showPassword"
                  class="c-input-small"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <SelectCity
                  name="id_city"
                  :id-country="employee.id_country"
                  :id-city="employee.id_city"
                  @onChange="onChange"
                  ref="selectCityRef"
                  class="c-input-small"
                />
              </v-col>
              <v-col cols="12">
                <SelectCounty
                  :id-city="employee.id_city"
                  :id-county="employee.id_county"
                  name="id_county"
                  @onChange="onChange"
                  class="c-input-small"
                />
              </v-col>
              <v-col cols="12">
                <SelectWard
                  :id-city="employee.id_city"
                  :id-county="employee.id_county"
                  :id-ward="employee.id_ward"
                  name="id_ward"
                  @onChange="onChange"
                  class="c-input-small"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="employee.address"
                  autocomplete="off"
                  :label="`${$t('labels.address')} *`"
                  outlined
                  dense
                  hide-details
                  class="c-input-small"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="text-center">
                <ImageViewer
                  v-if="employee.image_portrait"
                  :url="employee.image_portrait"
                  height="50px"
                />
                <v-btn
                  x-small
                  color="info"
                  @click="showImageUpload('image_portrait')"
                  block
                >
                  {{ $t("labels.avatar") }}
                </v-btn>
              </v-col>
              <v-col cols="4" class="text-center">
                <ImageViewer
                  v-if="employee.image_idcard_front"
                  :url="employee.image_idcard_front"
                  height="50px"
                />
                <v-btn
                  x-small
                  color="warning"
                  @click="showImageUpload('image_idcard_front')"
                  block
                >
                  {{ $t("labels.identity_front") }}
                </v-btn>
              </v-col>
              <v-col cols="4" class="text-center">
                <ImageViewer
                  v-if="employee.image_idcard_back"
                  :url="employee.image_idcard_back"
                  height="50px"
                />
                <v-btn
                  x-small
                  color="warning"
                  @click="showImageUpload('image_idcard_back')"
                  block
                >
                  {{ $t("labels.identity_rear") }}
                </v-btn>
              </v-col>
              <input
                type="file"
                ref="inputUploadFile"
                accept="image/*"
                @change="onInputFileChange"
                class="d-none"
              />
              <v-spacer></v-spacer>
              <v-col cols="12">
                <v-btn
                  block
                  color="success"
                  @click="register"
                  :disabled="isDisabledBtn"
                >
                  {{ $t("labels.register") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card outlined class="mx-auto" v-else>
          <div class="text-center pa-5 fs-22px">
            <div>
              {{ $t("labels.sub_account_register_success") }}
            </div>
            <div>
              {{ $t("labels.sincerely_thank") }}
            </div>
          </div>
        </v-card>
      </div>

      <v-dialog v-model="otpDialog" persistent max-width="360px">
        <otp
          v-if="otpDialog"
          :phone-number="employee.phone"
          @cancel="hideOtpDialog"
          @success="onConfirmSuccess"
        />
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { compress } from "image-conversion";
import moment from "moment";
import { httpClient } from "@/libs/http";

export default {
  name: "Register",
  components: {
    SelectCountry: () => import("@/components/common/SelectCountry"),
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  data: () => ({
    isSuccess: false,
    isOtpConfirmed: false,
    otpDialog: false,
    employee: {
      name: null,
      phone: null,
      password: null,
      address: null,
      id_country: null,
      id_city: null,
      id_county: null,
      id_ward: null,
      id_customer: null,

      image_portrait: null,
      image_idcard_front: null,
      image_idcard_back: null,
    },
    uploadType: null,
    showPassword: false,
    isLoading: false,
  }),
  computed: {
    isDisabledBtnNext() {
      return (
        !this.employee.id_country ||
        !this.employee.phone ||
        !this.isPhoneValid(this.employee.phone)
      );
    },
    isDisabledBtn() {
      if (this.isLoading) {
        return true;
      }
      if (
        !this.employee.phone ||
        (!this.isPhoneValid(this.employee.phone) &&
          !this.isEmailValid(this.employee.phone))
      ) {
        return true;
      }
      if (!this.employee.password || this.employee.password.length < 6) {
        return true;
      }

      const keys = Object.keys(this.employee);
      let isInvalid = false;
      keys.forEach((k) => {
        if (!this.employee[k]) {
          isInvalid = true;
        }
      });
      return isInvalid;
    },
  },
  mounted() {
    const code = this.getUrlParameter("c");
    if (!code) {
      window.location.href = "/";
    }
    const id_customer = this.decodeNumber(code);
    if (!id_customer || !Number.isInteger(id_customer)) {
      window.location.href = "/";
    }
    this.employee = { ...this.employee, id_customer };
  },
  methods: {
    async checkOtp() {
      if (this.employee.id_country === 1) {
        try {
          await httpClient.post("/check-phone-sub-account", this.employee);
          this.isOtpConfirmed = false;
          this.showOtpDialog();
          return true;
        } catch (e) {
          const errMsg =
            (e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.message) ||
            null;
          this.$vToastify.error(errMsg);
          return false;
        }
      }

      this.isOtpConfirmed = true;
    },
    onConfirmSuccess() {
      this.isOtpConfirmed = true;
      this.hideOtpDialog();
    },
    showOtpDialog() {
      this.otpDialog = true;
    },
    hideOtpDialog() {
      this.otpDialog = false;
    },
    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      let files = e.target.files || e.dataTransfer.files;
      if (files && files[0]) {
        const filePath = await this.uploadFile(files[0]);
        if (filePath) {
          this.employee[
            this.uploadType
          ] = `${process.env.VUE_APP_FILE_CDN_URL}/${filePath}`;
        }
        this.$refs.inputUploadFile.value = null;
      }
      this.isLoading = false;
    },
    async uploadFile(file) {
      const resBlob = await compress(file, {
        quality: 0.85,
        type: "image/jpeg",
      });
      const newFile = new File(
        [resBlob],
        `${moment().unix()}_${this.generateRandomString(10)}.jpg`,
        { type: "image/jpeg" }
      );
      let fd = new FormData();
      fd.append("file", newFile);

      const { data } = await httpClient.post(`/upload-image`, fd);
      return data;
    },
    async register() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/register-sub-account", {
          ...this.employee,
          ioc: this.isOtpConfirmed,
        });
        this.isSuccess = true;
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.register_success"));
        const employee = {
          name: null,
          phone: null,
          password: null,
          address: null,
          id_country: null,
          id_city: null,
          id_county: null,
          id_ward: null,
          id_customer: null,

          image_portrait: null,
          image_idcard_front: null,
          image_idcard_back: null,
        };
        this.employee = { ...employee };
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    showImageUpload(type) {
      this.uploadType = type;
      this.$refs.inputUploadFile.click();
    },
    onChange(item) {
      this.employee = { ...this.employee, [item.name]: item.value };
    },

    changeLanguage(lang) {
      localStorage.setItem("_lang", lang);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-img {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("https://file.ssc.eco/background/bg_sub.jpg");
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
    z-index: 1;
  }
}

.container {
  position: relative;
  z-index: 2;
}

.text-h5 {
  color: #fff;
}
</style>
